<template>
  <div>
    <b-overlay
      :show="showOver"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <div class="card">
        <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
          <div class="anan-tabs__nav">
            <div
              class="anan-tabs__nav-warp px-2 table-container"
              style="margin-top: 5px;"
            >
              <div
                class="anan-tabs__nav-tabs"
                style="transform: translateX(0px)"
              >
                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'all' }"
                  style="white-space: normal"
                  @click="getData(status = 0,search_val), isActive = 'all'"
                >
                  <!-- @click="GetOrderAll(0), isActive = 'all'" -->
                  <span> {{ $t('all') }} </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'not print' }"
                  style="white-space: normal"
                  @click="getData(status = 1,search_val), isActive = 'not print'"
                >
                  <!--  @click="GetOrderAll(1), isActive = 'not print'" -->
                  <span> {{ $t('notPrint') }} </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'printed' }"
                  style="white-space: normal"
                  @click="getData(status = 2,search_val), isActive = 'printed'"
                >
                  <!--  @click="GetOrderAll(1), isActive = 'printed'" -->
                  <span> {{ $t('printed') }} </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'waiting' }"
                  style="white-space: normal"
                  @click="getData(status = 3,search_val), isActive = 'waiting'"
                >
                  <!-- @click="GetOrderAll(2), isActive = 'waiting'" -->
                  <span> {{ $t('pendingReview') }} </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'success' }"
                  style="white-space: normal"
                  @click="getData(status = 4,search_val), isActive = 'success'"
                >
                  <!--  @click="GetOrderAll(4), isActive = 'success'" -->
                  <span> {{ $t('operationCompleted') }} </span>
                </div>
                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'cancel' }"
                  style="white-space: normal"
                  @click="getData(status = 5,search_val), isActive = 'cancel'"
                >
                  <!--  @click="GetOrderAll(4), isActive = 'success'" -->
                  <span> {{ $t('cancelled') }} </span>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="d-lg-flex justify-content-between align-items-center px-2">
          <div>
            <h3 class="font-weight-bolder">
              {{ $t('receipt') }}
            </h3>
          </div>

          <div class="d-flex align-items-center">
            <div class="anan-input__inner anan-input__inner--normal">
              <input
                v-model="search_val"
                type="text"
                :placeholder="this.$t('search')"
                class="anan-input__input"
                @keyup.enter="getData(status,search_val)"
              >
              <div class="anan-input__suffix">
                <button
                  type="button"
                  class="anan-button anan-button--normal anan-button--primary"
                  @click="getData(status,search_val)"
                >
                  <i class="fal fa-search" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="p-2">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="items"
            :fields="fields"
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  label="กำลังโหลด..."
                  variant="danger"
                />
              </div>
            </template>

            <template #empty>
              <div class="text-center">
                <img
                  src="@/assets/images/anan-img/empty/empty.png"
                  alt="empty"
                  width="180px"
                >
                <p>No Data</p>
              </div>
            </template>

            <template #cell(billnumber)="data">
              <b-link
                :to="{name: 'admin-finance-receipt_edit', params: {id: data.item._id}}"
                target="_blank"
              >
                {{ data.item.billnumber }}
              </b-link>
            </template>

            <template #cell(itemnumber_text)="data">
              <b-link
                :to="{name: 'shipment_payment_edit', params: {id: data.item._id}}"
                target="_blank"
              >
                {{ data.item.itemnumber_text }}
              </b-link>
            </template>

            <template #cell(created_at)="data">
              {{ time(data.item.created_at) }}
            </template>
            <template #cell(dateokay)="data">
              {{ data.item.dateokay ? time(data.item.dateokay): '-' }}
            </template>
            <template #cell(username)="data">
              <b-link
                :to="{name: 'admin-employee-edit', params: {id: data.item.uid}}"
                target="_blank"
              >
                <!-- <span class="mb-0 text-black badge badge-warning"`````> {{ getType(data.item.transportType) }} </span> -->
                <span>{{ data.item.username }}</span>
              </b-link>
            </template>
            <template #cell(payment)="data">
              {{ Commas((data.item.payment - data.item.Shipping_cost) + (data.item.coupon ?
                data.item.coupon.discount_coupon: 0) ) }} ฿
            </template>

            <template #cell(filetex)="data">
              <img
                v-if="data.item.filetex"
                :src="GetImg('filetex',data.item.filetex)"
                alt="img-document"
                class="border"
                height="40"
                @click="showPicture(data.item.filetex)"
              >
              <span v-else>
                -
              </span>

              <vue-image-lightbox-carousel
                ref="lightbox"
                :show="showLightbox"
                :images="images"
                :show-caption="false"
                @close="closeBox()"
              />
            </template>
            <template #cell(typetex)="data">
              <span v-if="data.item.typetex === 1"> {{ $t('Individual') }} </span>

              <span v-else-if="data.item.typetex === 2"> {{ $t('legalEntity') }}
              </span>
              <span v-else> -
              </span>
            </template>

            <template #cell(statusbill)="data">
              <span
                v-if="data.item.statusbill === 1"
                class="badge badge-light-success"
              > {{ $t('operationCompleted') }}
              </span>
              <span
                v-else-if="data.item.statusbill === 2"
                class="badge badge-light-danger"
              > {{ $t('cancelled') }}
              </span>
              <span
                v-else
                class="badge badge-light-primary "
              > {{ $t('pendingReview') }}
              </span>
              <span v-if="data.item.statusprint">
                <span
                  v-if="data.item.statusbill === 1"
                  class="badge badge-light-success "
                >
                  {{ $t('printed') }}
                </span>
                <span
                  v-else
                  class="badge badge-light-primary"
                >
                  {{ $t('printed') }}
                </span>

              </span>
            </template>

            <template #cell(actions)="data">
              <router-link
                :to="{name : 'admin-finance-receipt_edit', params: { id: data.item._id }}"
                target="_blank"
              >
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                />
              </router-link>
              <feather-icon
                v-if="data.item.statusprint"
                icon="PrinterIcon"
                class="text-danger cursor-pointer"
                @click="printbill(data.item)"
              />
              <feather-icon
                v-else
                icon="PrinterIcon"
                class="text-success cursor-pointer"
                @click="printbill(data.item)"
              />
            </template>
          </b-table>

          <div class="demo-spacing-0 d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="getData(status,search_val)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>

        </div>
      </div>
    </b-overlay>
    <!-- modal -->
    <b-modal
      ref="my-modal"
      hide-footer
      title="รูปภาพสินค้า"
    >
      <img
        :src="pic"
        alt="..."
        class="img-fluid rounded"
      >

      <div class="text-center">
        <button
          type="button"
          class="btn btn-primary mt-2"
          @click="downloadImg"
        >
          Download
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import {
  BPagination, BLink, BModal, BTable, BSpinner, BOverlay,
} from 'bootstrap-vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BPagination,
    BLink,
    BModal,
    BTable,
    BSpinner,
    BOverlay,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      search: '',
      isActive: 'all',
      selectedRadio: 'active',
      optionsRadio: [
        { text: 'All', value: 'all' },
        { text: 'Car', value: 'car' },
        { text: 'Ship', value: 'ship' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,

      items: [
        // {
        //   id: 1,
        //   number: 'GUSR-0001',
        //   deliveryNumber: 'GUSR-0001',
        //   customer: 'GUSR-0001',
        //   transactionDate: 'GUSR-0001',
        //   approvalDate: '-',
        //   noun: 'บริษัท ชิดา คอมเมอร์เชียล (ประเทศไทย) จำกัด',
        //   document: 'https://plmcdn.azureedge.net/upload/images/receipt/7a796ac0.jpg',
        //   customerType: 'บุคคลธรรมดา',
        //   amount: 100000,
        //   status: 'waiting',
        // },

        // {
        //   id: 2,
        //   number: 'GUSR-0001',
        //   deliveryNumber: 'GUSR-0001',
        //   customer: 'GUSR-0001',
        //   transactionDate: 'GUSR-0001',
        //   approvalDate: '-',
        //   noun: 'บริษัท ชิดา คอมเมอร์เชียล (ประเทศไทย) จำกัด',
        //   document: 'https://plmcdn.azureedge.net/upload/images/receipt/72655903.jpg',
        //   customerType: 'นิติบุคคล',
        //   amount: 100000,
        //   status: 'success',
        // },
      ],
      pic: null,

      total: 0,
      notpaid: 0,
      waitingforcheck: 0,
      waitingforpayment: 0,
      paid: 0,
      OrderSuccess: 0,
      completetrack: 0,
      completewarehouse: 0,
      cancel: 0,
      status: 0,
      search_val: null,
      showOver: false,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'billnumber', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'itemnumber_text', label: this.$t('invoiceNumber'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'username', label: this.$t('customer'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'created_at', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'dateokay', label: this.$t('approvalDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'texfullname', label: this.$t('name'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'filetex', label: this.$t('document'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'typetex', label: this.$t('customerType'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'payment', label: this.$t('quantity'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-right',
        },
        {
          key: 'statusbill', label: this.$t('status'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: this.$t('actions'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  watch: {
    '$route.query.querykey': {
      immediate: true,
      handler() {
        this.handleQueryChange()
      },
    },
  },
  mounted() {
    // this.handleQueryChange()
    // this.GetNotify()
    // this.GetOrderAll(0)
    // this.getData(this.status, this.search_val)
  },
  methods: {
    handleQueryChange() {
      const { querykey } = this.$route.query
      if (querykey) {
        if (Number(querykey) === 3) {
          this.isActive = 'waiting'
          this.getData(this.status = 3, this.search_val)
        } else if (Number(querykey) === 1) {
          this.isActive = 'not print'
          this.getData(this.status = 1, this.search_val)
        }
      } else {
        this.getData(this.status, this.search_val)
      }
    },
    // Commas(num) {
    //   return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // },
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    GetNotify() {
      this.$http
        .get('/order/Notify')
        .then(response => {
          // console.log(response.data)
          this.total = response.data.total
          this.waitingforcheck = response.data.waitingforcheck
          this.waitingforpayment = response.data.waitingforpayment
          this.paid = response.data.paid
          this.OrderSuccess = response.data.OrderSuccess
          this.completetrack = response.data.completetrack
          this.completewarehouse = response.data.completewarehouse
          this.cancel = response.data.cancel
        })
        .catch(error => console.log(error))
    },
    // eslint-disable-next-line camelcase
    async getData(type, search_val) {
      this.showOver = true
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        type,
        search_val,
      }
      this.$http
        .get('/Billpay/getdata', { params })
        .then(response => {
          // console.log(response.data)
          // response.data.data.map(ele => { ele.filetex = null })
          this.items = response.data.data
          // this.totalRows = this.items.length
          this.totalRows = response.data.total
          this.showOver = false
          // this.Showimg()
        })
        .catch(err => {
          this.SwalError(err)
          this.showOver = false
        })
      // try {
      //   const params = {
      //     perPage: this.perPage,
      //     page: this.page,
      //     type,
      //   }
      //   const { data: res } = await this.$http.get('/Billpay/getdata', { params })
      //   this.items = res.data
      //   this.totalRows = res.total
      // } catch (e) {
      //   console.log(e)
      // }
    },
    async Showimg() {
      const Id = this.items.map(ele => ele._id)
      const params = {
        id: Id,
      }
      this.$http.get('/Billpay/GetDataimg', { params })
        .then(response => {
          // console.log(response)
          response.data.forEach((data, index) => {
            this.items[index].filetex = data.filetex
          })
        })
        .catch(err => console.log(err))
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('filetex', picture) }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    // Billpay/GetDataimg
    GetOrderAll(type) {
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        type,
      }
      this.$http
        .get('/order/orderAll', { params })
        .then(response => {
          // console.log(response.data)
          this.items = response.data.data
          // this.totalRows = this.items.length
          this.totalRows = response.data.total
        })
        .catch(error => console.log(error))
    },
    printbill(data) {
      try {
        // eslint-disable-next-line no-underscore-dangle
        window.open(`https://api.anan-cargo.com/api/print/BillPdf/${data._id}`)
        // window.open(`http://127.0.0.1:4444/api/print/BillPdf/${data._id}`)
      } catch (e) {
        console.log(e)
      }
    },
    resize(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = 'auto'
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    updateKeyword(id, note) {
      // console.log('Item ID:', id)
      // console.log('note:', note)
      const params = {
        id,
        note,
      }
      // console.log(params)
      this.$http
        .post('/order/updateNote', params)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    showModal(data) {
      this.pic = data
      this.$refs['my-modal'].show()
    },
    downloadImg() {
      const imageURL = '@/assets/images/anan-img/example/1.jpg'
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image.jpg'
      link.click()
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

    <style lang="scss" scoped></style>
